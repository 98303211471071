/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

text-topo {
    color: #009FD6;
}

.topbuttonbar {
    margin: 0px 0px 5px 5px;
    padding: 6px;
    border-radius: 0.7rem;
    line-height: 0.5;
    font-size: 1.25rem;
}

.text-dropdown {
    color: #0D6EFD important;
}

.dropdown-item img {
    margin-right: 10px;  /* Add some space between the image and the category name */
    vertical-align: middle; /* Align the image with the text */
}

card-headertopo {
    background-color: #009FD6;
}

.cartform-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -2px;
    margin-bottom: -2px;
    background-color: white;
    border: -2px;
}

.maintcard {
    border-style: none;
    width: 240px;
    height: 295px;
    margin: 0px 0px 0px 0px;
}

.carousselcard {
    border-style: none;
    width: 400px;
    height: 350px;
    margin: 0px 0px 0px 0px;
}

.custom-col {
    background-color: #FFFFFF;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    margin-bottom: 7px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.customcart-col {
    background-color: #FFFFFF;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    margin-bottom: 7px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
    padding: 10px 15px 0px 15px
}

.customcarttable-col {
    background-color: #FFFFFF;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    margin-bottom: 7px !important;
    padding: 10px 15px 0px 15px
}

.custom-row {
    margin-bottom: 7px !important;
    padding: 10px 15px 0px 15px
}

.productcard {
    width: 24%; 
    /* example width width: 250px; */
    height: 325px;
    position: relative; /* Needed for absolute positioning of children */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    margin: 10px 0px 0px 7px;
    border: none; /* Removes the border */
    border-radius: 10px; /* or any value you like */
    transition: 0.3s; /* Optional: Adds a transition effect on hover */
  }
  
    .productcard img {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    max-height: 150px; /* Set a maximum height for the image */
    object-fit: contain; /* Contain the image within the element's box */
    }

    .productcard .card-body {
        overflow: hidden; /* Hide overflow content */
    }

    .productcard .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    .product-card.shadow-effect:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Optional: Enhances the shadow on hover */
      }

    .productcard button {
        position: absolute; /* Position the button absolutely within the card */
        bottom: -25; /* Align to the bottom */
        left: 0; /* Align to the left */
        width: 95%; /* Stretch across the entire width of the card */
      }

      .product-title {
        font-size: 16px; /* Smaller font size for title */
      }
      
      .product-description {
        font-size: 14px; /* Smaller font size for description */
      }
      


.categorycard {
    width: 30%; /* example width */
    height: auto; /* example height */
    background-color: #FFFFFF;
    position: relative; /* Needed for absolute positioning of children */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    margin: 7px 7px 7px 7px;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */ 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.categorycardsmall {
    width: 22%; /* example width */
    height: auto; /* example height */
    background-color: #FFFFFF;
    position: relative; /* Needed for absolute positioning of children */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    margin: 3px 3px 3px 3px;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */ 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.servicecard {
    width: 100%; /* example width */
    height: auto; /* example height */
    background-color: #FFFFFF;
    position: relative; /* Needed for absolute positioning of children */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    margin-bottom: 7px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */

    
}



.servicecard img {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    max-height: 150px; /* Set a maximum height for the image */
    object-fit: contain; /* Contain the image within the element's box */
    }

    .servicecard .card-body {
        overflow: hidden; /* Hide overflow content */
    }

    .servicecard .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    .servicecard.shadow-effect:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Optional: Enhances the shadow on hover */
      }

    .servicecard button {
        position: absolute; /* Position the button absolutely within the card */
        bottom: -25; /* Align to the bottom */
        left: 0; /* Align to the left */
        width: 95%; /* Stretch across the entire width of the card */
      }

      .servicecard-title {
        font-size: 16px; /* Smaller font size for title */
      }
      
      .servicecard-description {
        font-size: 14px; /* Smaller font size for description */
      }


.rentingcard {
    border-style: none;
    width: 240px;
    height: 325px;
    margin: 5px 5px 0px 5px;
}

.Occasioncard {
    border-style: none;
    width: 240px;
    height: 325px;
    margin: 5px 5px 0px 5px;
}

.Ordercard {
    border-style: none;
    width: 100%;
    height: 200px;
    margin: 5px 5px 0px 5px;
}

.productcardimage {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 230px;
}


.custom-rounded-img {
    border-radius: 10px; /* or any value you like */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}


.bodylayout {
    background-color: #f5f5f5;
    top: 25px;
}

.centercontainer {
    background-color: #FFF;
}

.colcontainer {
    background-color: #FFF;
    padding: 10px 15px 0px 15px
}

.orderdetails {
    background-color: #e5e4e4;
    width: 100%;
    padding: 5px 15px 0px 5px
}

.sidefilter {
    background-color: #FFF;
    padding: 10px 15px 0px 15px
}

.centeredcontainer {
    background-color: #FFF;
    align-content: center;
    position: center;
    padding: 10px 15px 0px 15px
}

.pagecontainer {
    margin-top: 130px;
    position: inherit;
    
    height: 50px;
    min-height: 0vh;
}

.headerlayout {
    background-color: #FFF;
}

.stickyheader {
    position: fixed;
    height: 100px;
    z-index: 10;
    top: 0px;
    width: 100%;
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand > img {
    padding: 7px 14px;
}

.col-4 {
    padding-top: 15px;
}

.btn-primary {
    color: #fff;
    background-color: #009FD6;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #e5e4e4;
    height: 2.5rem;
}

.footercontainer {
    margin-top: 10px;
    padding-bottom: 10px;
    background-color: #e5e4e4;
    align-content: center;
    position: center;
}

.logo {
    width: 50%;
    margin-bottom: 1rem;
}

.error {
    background-color: red;
}

.loginbox {
    display: flex;
    width: 100%;
    height: 100%;    
    margin: 0px 25px 0px;
    align-items:center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.logovendorsq-img {
    height: 100px;
    width: 100px;
    align-items: center;
    justify-content: center;
}

.logovendor-img {
    margin-top:20px;
    height: 90px;
    width: 200px;
    align-items: center;
    justify-content: center;
}

.product-img {
    height: 120px;
    width: 160px;
    align-items: center;
    justify-content: center;
}

.component-center {
    padding: 10px 15px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.component-center-limited {
    padding: 10px 15px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.survey-left {
    padding: 10px 15px 0px 15px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.survey-right {
    padding: 10px 15px 0px 15px;
    width: 60%;
    display: flex;
    align-items: left;
    justify-content: left;
}

.cartimage {
    height: 50px;
    width: 35px;
    align-items: center;
    justify-content: center;
}

.card-img, .card-img-top, .card-img-bottom {
    flex-shrink: 0;
    width: 60%;
}

.grid-column-container {
    display: flex;
}

.gridleft-column, gridright-column {
    width: 25%
}

.gridcenter-column {
    margin-left: 1.5%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center; /* Center the text inside the middle column */
}


.slimFormRow {
    margin-bottom: 0.25rem;
}

.carousselcard {
    background-color: #FFFFFF;
/*    border-style: none;*/
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    width: 400px;
    height: 350px;
    margin: 0px 25px 0px;
    align-items:center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.productcarousselcard {
    background-color: #FFFFFF;
/*    border-style: none;*/
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    width: 400px;
    height: 350px;
    margin: 0px 25px 0px;
    align-items:center;
}

.carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #009fd6;
    text-align: center;
    opacity: 1;
    transition: opacity 0.15s ease;
}

.navtabsleft {
    justify-content: flex-start;
  }


.topoH4 {
color: #0D6EFD;
}

.starcontainer {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

.errorsubmit {
    color: red;
}

.textwide {
    width: 90%;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.img-shadowdrop-checkout {
    width: 50%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    max-height: 200px; /* Set a maximum height for the image */
    object-fit: contain; /* Contain the image within the element's box */
    
}
