.custom-col {
    background-color: #FFFFFF;
    border: 1px solid light-grey;
    border-radius: 1rem; /* or any other value you prefer */
    margin-bottom: 7px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

  .custom-rounded-img {
    border-radius: 10px; /* or any value you like */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    /* Set your desired transition duration here */
    transition-duration: 1s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
  }
  
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
  }
  
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-prev.carousel-item-left,
  .carousel-fade .carousel-item-next.carousel-item-right {
    transform: translateX(0);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
  }
  
  /* Optional: add transition for sliding when manually controlling the Carousel */
  .carousel-fade .carousel-control-prev,
  .carousel-fade .carousel-control-next {
    z-index: 1;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
  }

  .logovendor-img {
    height: 100px; /* Fixed height for all images */
    object-fit: contain; /* Maintain the aspect ratio */
    object-position: top; /* Align the image to the top */
  }
  