a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}



html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* NavMenu.css */
.topologo {
  width: 150px;
  margin-top: -10px;
}

.navbar {
  flex-wrap:nowrap; /* Allow the nav to wrap to the next line */
}

.navbarlow {
  flex-wrap:nowrap; /* Allow the nav to wrap to the next line */
}

.nav {
  width: 100%;
  justify-content: flex-end;
}

.nav-link {
  margin: 0 5px; /* Add some spacing between nav items */
}


.horizontal-dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* If you don't want the items to wrap to the next line */
  padding: 0;
  border: none !important;
  box-shadow: none !important;
  left: 50% !important;  /* Move the left edge of the dropdown to the center of the screen */
  transform: translateX(-30%);  /* Shift the dropdown to the left by half of its width */
  position: absolute !important;  /* Ensure that the dropdown is positioned relative to the viewport */
}

.horizontal-dropdown .dropdown-item {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  margin-right: 15px; /* Adjust as needed */
}

.border-right {
  border-right: 1px solid #ddd; /* Adjust color as needed */
}